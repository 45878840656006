<template>
  <div>
    <div class="title">
      نتائج وظائف شركة مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
    </div>
    <v-divider></v-divider>
    <v-img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Loader.gif/480px-Loader.gif"
    >
    </v-img>
    <v-card color="basil" v-if="allcareer.length >= 1">
      <v-card-title class="text-center justify-center py-6">
        <h3 class="font-weight-bold text-h4 basil--text hidden-md-and-down">
          نتائج كل ما يخص إعلان الوظائف
        </h3>

        <h5 class="font-weight-bold text-h4 basil--text hidden-lg-and-up">
          نتائج كل ما يخص إعلان الوظائف
        </h5>
      </v-card-title>

      <v-tabs
        prev-icon="mdi-arrow-left-bold-box-outline"
        next-icon="mdi-arrow-right-bold-box-outline"
        show-arrows
        center-active
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tab class="titleCard" v-if="pcrFun.length">
          نتيجه فحص الورق
          <v-icon>mdi-database</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="gerFun.length">
          نتيجه فحص التظلمات
          <v-icon>mdi-eye</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="wedFun.length">
          مواعيد الإختبارات التحريريه
          <v-icon>mdi-earth-plus</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="wtrFun.length">
          نتائج الإختبار التحريري
          <v-icon>mdi-eye</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="pedFun.length">
          مواعيد الإختبار العملية
          <v-icon>mdi-earth-plus</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="ptrFun.length">
          نتائج الاختبار العملي
          <v-icon>mdi-eye</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="idcarFun.length">
          مواعيد المقابله الشخصيه
          <v-icon>mdi-earth-plus</v-icon>
        </v-tab>
        <v-tab class="titleCard" v-if="anFun.length">
          أسماء المقبولين
          <v-icon>mdi-eye</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!-- pcr --->
        <v-tab-item v-if="pcrFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelPcrFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in pcrFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- GER -->
        <v-tab-item v-if="gerFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelGerFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in gerFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- wed -->
        <v-tab-item v-if="wedFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelWedFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in wedFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- wtr -->
        <v-tab-item v-if="wtrFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelWtrFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in wtrFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- ped -->
        <v-tab-item v-if="pedFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelPedFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in pedFun"
                  :key="i"
                  class="changeTitleStyle "
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- ptr  -->
        <v-tab-item v-if="ptrFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelPtrFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in ptrFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- id -->
        <v-tab-item v-if="idcarFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelIdFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in idcarFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- an -->
        <v-tab-item v-if="anFun.length" :key="item">
          <v-card color="basil" flat>
            <v-card-text class="pa-10">
              <v-expansion-panels v-model="panelAnFun" multiple class="pa-4 ">
                <v-expansion-panel
                  v-for="(item, i) in anFun"
                  :key="i"
                  class="changeTitleStyle"
                >
                  <v-expansion-panel-header class="fade-in titlePanel">
                    {{ item.title }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h5  class="ql-editor" v-html="item.description"></h5>
                    <h3 class="green--text downloadText">
                      لتحميل الملف أضغط هنا
                      <a
                        :href="
                          'https://backend.ascww.org/api/careers/download/' +
                            item.file_path
                        "
                        ><v-icon class="green--text">mdi-file</v-icon></a
                      >
                    </h3>
                    <br />
                    <PDFJSViewer
                      :path="
                        'https://backend.ascww.org/api/careers/file/' +
                          item.file_path
                      "
                      :fileName="item.file_name"
                    />
                    <br />
                    <div v-if="item.image_path">
                      <v-img
                        :src="
                          'https://backend.ascww.org/api/careers/image/' +
                            item.image_path
                        "
                      ></v-img>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card color="basil" v-else>
      <v-card-title>
        <v-img
          :src="loadingLink"
          width="200px"
          height="200px"
          class="loadImgClass"
          contain
        ></v-img
      ></v-card-title>
    </v-card>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  components: { PDFJSViewer },
  data() {
    return {
      loadingLink: require("@/assets/Images/load.svg"),
      panel: [0, 1, 2, 3],
      itemsTraning: [
        {
          subTitle: [
            "إعداد الكوادر الإدارية والفنية المتخصصة كل في مجاله ",
            "تنمية الموارد البشرية بمساعدتها على اكتساب وتحسين المهارات والكفاءات للقيام بالواجبات الحالية والمستقبلية",
            " تمكين الشركة من العمل طبقا لمعايير الجودة المطلوبة ",
            "تعزيز فرص النمو والتطور لدى موظفي الشركة من أجل تنمية طاقاتهم",
          ],
          title: "أهدفناالاستراتيجيه",
          icon: "mdi-database",
        },
        {
          subTitle: [
            "الولاء والانتماء . المصداقية . الالتزام وبناء الثقة . الشفافية ",
          ],
          title: "قيمنا",
          icon: "mdi-earth-plus",
        },
        {
          subTitle: [
            "الإشراف الكامل على مختلف صور التدريب",
            "حسن اختيار الكوادر المسئولة عن إدارة منظومة التدريب.",
          ],
          title: "رسالتنا",
          icon: "mdi-camera",
        },
        {
          subTitle: [
            "الوصول إلى مستوى أداء عالمى فى إطار منظومة من القيم السائدة",
          ],
          title: "رؤيتنا",
          icon: "mdi-eye",
        },
        {
          subTitle: [
            "الوصول إلى مستوى أداء عالمى فى إطار منظومة من القيم السائدة",
          ],
          title: "رؤيتنا",
          icon: "mdi-eye",
        },
        {
          subTitle: [
            "الوصول إلى مستوى أداء عالمى فى إطار منظومة من القيم السائدة",
          ],
          title: "رؤيتنا",
          icon: "mdi-eye",
        },
        {
          subTitle: [
            "الوصول إلى مستوى أداء عالمى فى إطار منظومة من القيم السائدة",
          ],
          title: "رؤيتنا",
          icon: "mdi-eye",
        },
      ],
      tab: null,
    };
  },
  created() {
    this.$store.dispatch("findAllCareers");
    this.$store.dispatch("findAllCa");
    this.$store.dispatch("findAllPcr");
    this.$store.dispatch("findAllGer");
    this.$store.dispatch("findAllWed");
    this.$store.dispatch("findAllWtr");
    this.$store.dispatch("findAllPed");
    this.$store.dispatch("findAllPtr");
    this.$store.dispatch("findAllId");
    this.$store.dispatch("findAllAn");
  },

  computed: {
    allcareer() {
      return this.$store.state.careers;
    },
    caFun() {
      return this.$store.state.ca;
    },
    pcrFun() {
      return this.$store.state.pcr;
    },
    gerFun() {
      return this.$store.state.ger;
    },
    wedFun() {
      return this.$store.state.wed;
    },
    wtrFun() {
      return this.$store.state.wtr;
    },
    pedFun() {
      return this.$store.state.ped;
    },
    ptrFun() {
      return this.$store.state.ptr;
    },
    idcarFun() {
      return this.$store.state.idcar;
    },
    anFun() {
      return this.$store.state.an;
    },
    panelPcrFun() {
      return [this.pcrFun.length - 2, this.pcrFun.length - 1];
    },
    panelGerFun() {
      return [this.gerFun.length - 2, this.gerFun.length - 1];
    },
    panelWedFun() {
      return [this.wedFun.length - 1];
    },
    panelWtrFun() {
      return [this.wtrFun.length - 2, this.wtrFun.length - 1];
    },
    panelPedFun() {
      return [this.pedFun.length - 1];
    },
    panelPtrFun() {
      return [this.ptrFun.length - 2, this.ptrFun.length - 1];
    },
    panelIdFun() {
      return [this.idcarFun.length - 1];
    },
    panelAnFun() {
      return [this.anFun.length - 2, this.anFun.length - 1];
    },
  },
};
</script>
<style>
.loadImgClass {
  background-size: cover;
}
h3 {
  font-family: Arial, Helvetica, sans-serif;
}

.hrClass {
  color: #d8d8d8;
}
.basil {
  background-color: #fffbe6 !important;
}

.titleCard {
  letter-spacing: normal !important;
  font-weight: bold;
  background: none !important;
  padding: 3px;
}
.liCard {
  letter-spacing: normal;
}
.subTitle {
  color: #356859 !important;
}
.changeTitleStyle {
  background: rgba(135, 212, 222, 0.192) !important;
}
.v-slide-group__wrapper {
  justify-content: flex-end;
}
</style>
<!--<template>
  <div>
    <div>
      <div class="title">
        <h3>
          مياه أسيوط .. تعلن عن نتيجة الفنيين اللذين تم قبول تظلماتهم والغير
          مقبول تظلماتهم الشروطٍ بوظيفة (مراقب أمن) بالمسابقة رقم (4) لعام 2021م
        </h3>
      </div>
      <v-divider></v-divider>
      
      <v-container>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين اللذين تم قبول تظلماتهم لوظيفه (مراقب أمن)
            <a
              :href="`${publicPath}` + './files/tazlomat_amn_accepted.pdf'"
              download="tazlomat_amn_accepted.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${tazlomat_amn_accepted}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين اللذين لم يتم قبول تظلماتهم لوظيفه (مراقب أمن)
            <a
              :href="`${publicPath}` + './files/tazlomat_amn_notaccepted.pdf'"
              download="tazlomat_amn_notaccepted.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${tazlomat_amn_notaccepted}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="title">
        <h3>
          مياه أسيوط .. تعلن عن نتيجة الفنيين اللذين ينطبق ولا ينطبق عليهم
          الشروطٍ بوظيفة (مراقب أمن) بالمسابقة رقم (4) لعام 2021م
        </h3>
      </div>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (مراقب أمن) اللذين ينطبق عليهم الشروط
            <a
              :href="`${publicPath}` + './files/success_worker.pdf'"
              download="success_worker.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer :path="`${path}`" :fileName="`${name}`" />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (مراقب أمن) اللذين لا ينطبق عليهم الشروط
            <a
              :href="`${publicPath}` + './files/not_success_worker.pdf'"
              download="not_success_worker.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${name_not_succ}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="title">
        <h3>
          مياه أسيوط .. تعلن عن نتيجة الفنيين اللذين ينطبق ولا ينطبق عليهم
          الشروطٍ بوظيفة (فني) بالمسابقة رقم (4) لعام 2021م
        </h3>
      </div>
      <v-container>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (فني ) اللذين ينطبق عليهم الشروط
            <a
              :href="`${publicPath}` + './files/fani_success.pdf'"
              download="fani_success.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${fani_success}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (فني) اللذين لا ينطبق عليهم الشروط
            <a
              :href="`${publicPath}` + './files/fani_not_success.pdf'"
              download="fani_not_success.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${fani_not_success}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="title">
        <h3>
          مياه أسيوط .. تعلن عن نتيجة الفنيين اللذين تم قبول تظلماتهم والغير
          مقبول تظلماتهم الشروطٍ بوظيفة (فني) بالمسابقة رقم (4) لعام 2021م
        </h3>
      </div>
      <v-container>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (فني ) اللذين تم قبول تظلماتهم
            <a
              :href="`${publicPath}` + './files/tazlomat_accepted.pdf'"
              download="tazlomat_accepted.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${tazlomat_accepted}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <h3 class="indigo--text">
            كشف بأسماء المتقدمين لوظيفه (فني) الغير مقبول تظلماتهم
            <a
              :href="`${publicPath}` + './files/tzalomat_not_accepted.pdf'"
              download="tzalomat_not_accepted.pdf"
              ><v-icon class="indigo--text">mdi-file</v-icon></a
            >
          </h3>
        </v-row>
        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      :path="`${path}`"
                      :fileName="`${tzalomat_not_accepted}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  components: {
    PDFJSViewer,
  },
  data() {
    return {
      name: "success_worker.pdf", //change which pdf file loads
      name_not_succ: "not_success_worker.pdf",
      fani_success: "fani_success.pdf",
      fani_not_success: "fani_not_success.pdf",
      tazlomat_accepted: "tazlomat_accepted.pdf",
      tzalomat_not_accepted: "tzalomat_not_accepted.pdf",
      tazlomat_amn_accepted: "tazlomat_amn_accepted.pdf",
      tazlomat_amn_notaccepted: "tazlomat_amn_notaccepted.pdf",
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h1 {
  color: #1f659b;
}
</style>
-->
